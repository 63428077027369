<template>
<div id="jobFairs">
  <div class="banner">
    <span>{{numberOfPeriods}}网络招聘会</span>
  </div>
  <div class="positionWrap">
    <div class="filter-box">
      <div class="search-box">
        <el-input v-model="conditionForm.searchValue" class="searchCt" placeholder="搜索职位、公司"></el-input>
        <div class="search-icon" @click="getPopularEnterprises">
          搜索
        </div>
      </div>
      <div class="address">
        <span>工作地点：</span>
        <label v-for="(item,index) in areaList" :key="index" @click="toggleArea(item.areaCode,index)" :class="{activeSub:areaSub==index}">
          {{item.areaName}}
        </label>
      </div>
      <div class="filter-ct">
        <el-select @change="getPopularEnterprises" clearable v-model="conditionForm.workExperience" placeholder="工作经验">
          <el-option v-for="item in workList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-select @change="getPopularEnterprises" clearable v-model="conditionForm.minimumEducation" placeholder="学历要求">
          <el-option v-for="item in backgroundList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-select @change="getPopularEnterprises" clearable v-model="conditionForm.enterpriseNature" placeholder="企业性质">
          <el-option v-for="item in natureList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-select @change="getPopularEnterprises" clearable v-model="conditionForm.scale" placeholder="企业规模">
          <el-option v-for="item in scaleList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <div class="reset-btn" @click="reset">重置</div>
      </div>
    </div>
     <div class="popular-list" v-show="popularList.length>0" v-loading="loadPopular">
         <div class="popular-item" v-for="(item,index) in popularList" :key="index" @click="jumpCompany(item)">
            <div class="info">
              <img :src="item.enterpriseLogo" />
              <p>
                <span>{{item.enterpriseName}}</span>
                <label v-show="item.enterpriseNatureName">{{item.enterpriseNatureName}}</label>
                <label v-show="item.industryName">{{item.industryName}}</label>
                <label v-show="item.scaleName">{{item.scaleName}}</label>
              </p>
            </div>
            <div class="hot">
              热招<i v-show="item.hotInfo.positionName"></i>
              <span>{{item.hotInfo.positionName}}</span>
              <i v-show="item.hotInfo.minimumWage"></i>
              <label>
                {{item.hotInfo.minimumWage}}<label v-show="item.hotInfo.maximumSalary">-{{item.hotInfo.maximumSalary}}</label>
              </label>
            </div>
         </div>
     </div>
     <el-empty v-show="popularList.length==0" :image-size="200" description="暂时没有符合该搜索条件的职位"></el-empty>
     <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="conditionForm.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="conditionForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import { getOnlineJobFairs, getAreaList, getDictData } from "@/api/index.js";
export default {
  name: "jobFairs",
  data() {
    return {
      popularList: [],
      userInfo:{},
      conditionForm: {
        position:'',
        searchValue: '',
        region: '',
        workExperience: '',
        enterpriseNature:'',
        minimumEducation:'',
        scale:'',
        pageSize:10,
        pageNum:1,
      },
      areaSub: 0,
      areaList: [],
      workList: [],
      natureList: [],
      backgroundList: [],
      scaleList:[],
      subList:[],
      total:0,
      loadPopular:true,
      numberOfPeriods:''
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getPopularEnterprises()
    this.getAreaList()
    this.getDictData('work_experience')
    this.getDictData('enterprise_nature')
    this.getDictData('background_type')
    this.getDictData('scale_enterprises')
  },
  methods: {
     sizeChange(val){
      this.conditionForm.pageSize = val
      this.getPopularEnterprises()
    },
    currentChange(val){
      this.conditionForm.pageNum = val
      this.getPopularEnterprises()
    },
    jumpCompany(item){
     if(this.userInfo.type==3){
        this.$router.push({
          path: '/login',
        })
      }else{
        this.$router.push({
          path: '/companyDetail',
          query: {
            enterpriseId:item.enterpriseId
          }
        })
      }
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'work_experience') {
          this.workList = res.rows
        }
        if (dictType == 'enterprise_nature') {
          this.natureList = res.rows
        }
        if (dictType == 'background_type') {
          this.backgroundList = res.rows
        }
        if (dictType == 'scale_enterprises') {
          this.scaleList = res.rows
        }
      })
    },
    reset(){
        this.conditionForm.workExperience = ''
        this.conditionForm.enterpriseNature = ''
        this.conditionForm.minimumEducation = ''
        this.conditionForm.scale = ''
        this.conditionForm.position = ''
        this.getPopularEnterprises()
    },
    toggleArea(areaCode,index) {
      this.conditionForm.region = areaCode
      this.areaSub = index
      this.getPopularEnterprises()
    },
    getAreaList() {
      getAreaList().then(res => {
        this.areaList = res.rows
      })
    },
    getPopularEnterprises() {
      this.loadPopular = true
      getOnlineJobFairs(this.conditionForm).then(res => {
        this.loadPopular = false
        res.data.onlineJobFairsList.forEach(item=>{
          item.hotInfo = item.positionInfoList[0] ||{}
        })
        this.popularList = res.data.onlineJobFairsList
        this.total = res.data.onlineJobFairsList.length
        this.numberOfPeriods = res.data.numberOfPeriods
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#jobFairs {
  width: 100%;
  min-height: 800px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
}
.banner{
  width: 100%;
  height: 180px;
  line-height: 180px;
  text-align: center;
  background: url('../../assets/imgs/company/fairsBanner.png');
  background-size: cover;
  span{
    color: #fff;
    font-size: 30px;
    letter-spacing: 1px;
  }
}
 .address{
  margin-bottom: 20px;
 }
.positionWrap {
  width: 1200px;
  margin: 20px auto;
}
.pagination{
  clear: both;
  margin-top: 20px;
}
.filter-box {
  min-height: 60px;
  padding: 25px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 13px;

  span {
    font-size: 16px;
    color: #222222;
    display: inline-block;
    margin-right: 5px;
  }

  label {
    font-size: 14px;
    display: inline-block;
    margin-right: 25px;
    cursor: pointer;

  }

  .activeSub {
    color: #0077FF;
  }
  .reset-btn{
    width: 100px;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius:4px;
    text-align: center;
    font-size: 13px;
    color: #fff;
    background: #0077FF;
   display: inline-block;
   cursor: pointer;
  }
}
.popular-list{
  width: 1210px;
  overflow: hidden;
}
.popular-item{
  height: 150px;
  width: 390px;
  margin-right: 13px;
  margin-bottom: 13px;
  float: left;
  background: #fff;
  border-radius: 12px;
  cursor: pointer;
  .info{
    cursor: pointer;
    img{
      width: 60px;
      height: 60px;
      border: 1px solid #f2f5fa;
      border-radius: 10px;
      float: left;
      margin: 18px;
    }
    p{
      float: left;
      margin-top: 18px;
      span{
        display: block;
        font-size: 17px;
        color: #222;
        width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span:hover{
        color: #0077FF
      }
      label{
        display: inline-block;
        color: #666;
        padding: 2px 8px;
        background: #f8f8f8;
        font-size: 13px;
        border-radius: 4px;
        max-width: 68px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        margin-right: 8px;
        margin-top: 12px;
      }
    }
  }
  .hot{
    clear: both;
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    color: #666;
    padding: 0 20px;
    background: linear-gradient(90deg,#f5fcfc 0,#fcfbfa 100%);
    border-radius: 0 0 12px 12px;
    span{
      color: #0077FF;
      font-size: 14px;
    }
    i{
      display: inline-block;
      width: 1px;
      height: 10px;
      margin:0 15px;
      vertical-align: middle;
      background: #e0e0e0;
    }
  }
}
.popular-item:hover{
  box-shadow: 0 16px 40px 0 rgba(140, 152, 161, 0.15);
  transition: all .4s;
}
.activePosition{
  color: #0077FF;
  background: #f8f8f8;
}
</style>
<style lang="scss">
.filter-box{
  .search-box{
    margin-left: 0;
     .el-input__inner{
      width: 770px;
      border: 1px solid #0077FF;
     }
     .search-box .search-icon {
       border: 1px solid #0077FF;
     }
  }
}
</style>
